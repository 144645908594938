import cmrApi from "@/api/CmrApi"
import Swal from "sweetalert2";
import { i18n } from '@/main.js'



export const getCmrs = (state,getters,rootState) => async () => {
    const vada = rootState.vada
    const path = `/api/v1/load?delete=false&vada=${vada}&closing=false`
    cmrApi.get(path).then(response => {
    if (response.status === 200) {
        state.cmrs = [...response.data]
        state.isLoading = false
    } else {
        state.isLoading = false
    }
    })
}

export const getCmrByCmr = (state) => async (cmrNumber) => {
    state.isLoading = true
    const path = `/api/v1/cmrByNumber?cmrNumber=${cmrNumber}`
    const response = cmrApi.get(path).then(getResponse => {

    if (getResponse.status === 400) {
        return getResponse.data
    } else {
        state.cmr = getResponse.data
        state.isLoading = false
        return getResponse.data
    }
    })
    return response
}

export const getPackaging = (state) => async () => {
    const path = `/api/v1/packaging`
    cmrApi.get(path).then(response => {
    if (response.status === 200) {
        state.packaging = [...response.data]
    }
    })
    .catch(err => {
        Swal.fire({
            icon: "error",
            title: `Error while getting a package`,
            text: err.response.data.detail,
            confirmButtonColor: "#414DBC",
            cancelButtonColor: "#6e7d88"
        })
    })
}

export const getReturns = (state,getters,rootState) => async () => {
    const vada = rootState.vada
    const path = `/api/v1/return?vada=${vada}&closing=false&delete=false`
    cmrApi.get(path).then(response => {
    if (!response.data) return
    state.returns = [...response.data]
    })
}

export const getShipmentInfoFromBoomi = () => async (shipmentNumber,gln) => {
    Swal.fire({
        title: i18n.global.t('waitPlease'),
        text:i18n.global.t('searchingShipmentAS400'),
        allowOutsideClick: false,
        confirmButtonColor: "#414DBC",
        cancelButtonColor: "#6e7d88"
      });
    Swal.showLoading(); 
    const path = `/api/v1/getShipmetFromBoomi?shipmentNumber=${shipmentNumber}&gln=${gln}`
        const response = cmrApi.get(path).then(response => {
            if (response.status === 200) {
                Swal.close()
                return response.data
            }
            })
            .catch(err => {
                Swal.fire({
                    icon: "error",
                    title: `Error while getting shipment info from AS400`,
                    text: "Please, check if the shipment number is correct. " + err.response.data.detail,
                    confirmButtonColor: "#414DBC",
                    cancelButtonColor: "#6e7d88"
                })
            })
            return response
}

export const getRaqInfoFromBoomi = () => async (storeNumber, gln) => {
    Swal.fire({
        title: i18n.global.t('waitPlease'),
        text: i18n.global.t('searchingRaqAS400'),
        allowOutsideClick: false,
        confirmButtonColor: "#414DBC",
        cancelButtonColor: "#6e7d88"
    });
    Swal.showLoading(); 

    const path = `/api/v1/getRaqFromBoomi?storeNumber=${storeNumber}&gln=${gln}`;

    try {
        const response = await cmrApi.get(path);

        if (response.status === 200) {
            Swal.close();
            return response;
        }
    } catch (err) {
        let raqOkOrError;

        if (err.response.data.detail.startsWith('[404 Not Found]')) {
            const result = await Swal.fire({
                icon: "question",
                title: i18n.global.t('atention'),
                text: i18n.global.t('raqQuestion'),
                confirmButtonColor: "#414DBC",
                cancelButtonColor: "#6e7d88",
                showCloseButton: true,
                showCancelButton: true,
                confirmButtonText: 'SI',
                cancelButtonText: 'NO'
            });

            if (result.isConfirmed) {
                raqOkOrError = 'OK';
                return raqOkOrError;
            } else {
                await Swal.fire({
                    icon: "info",
                    title: i18n.global.t('thenErrorRaq'),
                    text: i18n.global.t('thenErrorRaqDesc'),
                    confirmButtonColor: "#414DBC",
                    cancelButtonColor: "#6e7d88"
                });
                raqOkOrError = 400;
                return raqOkOrError;
            }
        } else {
            await Swal.fire({
                icon: "error",
                title: i18n.global.t('errorRaq'),
                text: i18n.global.t('errorRaqDesc') + " -->" + err.response.data.detail,
                confirmButtonColor: "#414DBC",
                cancelButtonColor: "#6e7d88"
            });
            raqOkOrError = 500;
            return raqOkOrError;
        }
    }
};

export const getShipmentNumberFromBoomi = () => async (gln, typeTier, destinationNumber) => {
    try {
        Swal.fire({
            title: i18n.global.t('waitPlease'),
            text: i18n.global.t('searchingShipmentNumberAS400'),
            allowOutsideClick: false,
            confirmButtonColor: "#414DBC",
            cancelButtonColor: "#6e7d88"
        });
        Swal.showLoading(); 

        const path = `/api/v1/getShipmentNumberFromBoomi?gln=${gln}&typeTier=${typeTier}&destinationNumber=${destinationNumber}`;
        
        // Esperamos a la respuesta
        const response = await cmrApi.get(path);

        // Si la respuesta es 200, mostramos la alerta de advertencia
        if (response.status === 200) {
            return response.data.shipmentNumber;
        }
    } catch (err) {
        // Si hay un error, mostramos la alerta de error
        console.error("Impossible to retrieve Shipment Number from AS400: " + err);
        
        Swal.fire({
            icon: "error",
            title: i18n.global.t('shipmentNotFound'),
            text: i18n.global.t('shipmentNotFoundAdvice') + " -->" + (err.response?.data?.detail || err.message),
            confirmButtonColor: "#414DBC",
            cancelButtonColor: "#6e7d88"
        });
    }
};
